import debug from "debug";

const APP_NAME = "bbs";

interface JSONRPCCall {
    id: number | string
    method: string
    params: any
    // jsonrpc: string
}

interface JSONRPCResult {
    id: number | string
    result?: any
    error?: any
    // jsonrpc: string
}


const arrowCSS = "font-size:14px;font-weight:700;font-family:Inconsolata,Menlo,monospace;border-radius:2px 0 0 2px;padding:2px 0;"
const batchArrowCSS = "font-size:14px;font-weight:700;font-family:Inconsolata,Menlo,monospace;border-radius:2px 0 0 2px;color:#fff;background:rgba(0,0,0,0.2);padding:2px 4px;"
const elapsedCSS = arrowCSS + "color:magenta;margin-left:7px;";
function bgCSS(hue: number, dimmed = false) {
    return `background:hsla(${hue}, 100%, 60%, ${dimmed ? 0.6 : 1});`
}
const _idCSS = `${arrowCSS}padding:2px 4px;`
const idCSS = (color: number, dimmed = false) =>
    bgCSS(color) + _idCSS

const _methCSS = _idCSS + "border-left:1px solid rgba(0,0,0,0.4);border-radius:0 2px 2px 0;"
const methodCSS = (color: number, dimmed = false) =>
    bgCSS(color, dimmed) + _methCSS

export default class Logger {

    _debug: debug.Debugger
    _warn: debug.Debugger
    _error: debug.Debugger

    pending: {
        [id: number | string]: {
            method: string,
            start: Date,
            color: number,
            tid: number,
            // params: Record<string, any>
            batch: boolean
        }
    } = {}
    _color = 150

    constructor(prefix?: string) {
        if (prefix) {
            this._debug = debug(`${APP_NAME}:${prefix}`);
            this._warn = debug(`${APP_NAME}:WARN:${prefix}`);
            this._error = debug(`${APP_NAME}:ERROR:${prefix}`);
        } else {
            this._debug = debug(APP_NAME);
            this._warn = debug(`${APP_NAME}:WARN`);
            this._error = debug(`${APP_NAME}:ERROR`);
        }

        /* eslint-disable no-console */
        this._debug.log = console.log.bind(console);
        this._warn.log = console.warn.bind(console);
        this._error.log = console.error.bind(console);

        /* eslint-enable no-console */


    }
    color() {
        const c = (this._color % 175) + 45
        this._color += 15
        return c
    }

    logRPCBatchReq(reqs: JSONRPCCall[]) {
        const color = this.color()
        reqs.forEach((r) => this.logRPCReq({ ...r, color }, true))
    }
    logRPCReq({ id, method, params, color }: JSONRPCCall & { color?: number }, batch = false) {
        color ??= this.color()
        const pc = {
            method, start: new Date(), color, batch
        }


        const tid = setTimeout(() => {
            this.logRPCRes({ id, error: { code: 408, message: "RPC Timeout" } })
        }, 5 * 1000)

        this.pending[id] = { ...pc, tid }
        const arrow = batch ? "->" : '->'

        this.log(
            `%c${arrow} %c${id} %c${method}`,
            batch ? batchArrowCSS : arrowCSS,
            idCSS(color),
            methodCSS(color),
            // `font-size:14px;font-weight:700; color:#00A;background:hsla(${color}, 100%, 40%, 0.2);padding: 2px 4px;border-radius:2px;font-family: Inconsolata, Menlo, monospace;`,
            // `font-size:14px;font-weight:700; color:#00A;background:#d1e3ff;padding: 2px 4px;border-radius:2px;font-family: Inconsolata, Menlo, monospace;`,
            params
        );
    }
    logRPCRes(res: JSONRPCResult | JSONRPCResult[]) {
        if (Array.isArray(res)) {
            res.forEach(r => this._logRPCRes(r))
        }
        //@ts-ignore
        this._logRPCRes(res as JSONRPCResult, res.id[0] == "b")
    }
    _logRPCRes({ id, result, error }: JSONRPCResult) {
        const { color, method, start, tid, batch } = this.pending[id]
        const elapsed = new Date().getTime() - start.getTime()

        clearTimeout(tid)
        const arrow = batch ? "<-" : '<-'
        if (!error) {

            this.log(
                `%c${arrow} %c${id} %c${method} %c${elapsed}ms`,
                batch ? batchArrowCSS : arrowCSS,
                idCSS(color),
                methodCSS(color, true),
                elapsedCSS,
                result

            );
        } else {
            this.log(
                `%c${arrow} %c${id} ${method}() ERROR: code:${error.code}, message: ${error.message} %c${elapsed}ms`,
                batch ? batchArrowCSS : arrowCSS,
                `font-size:14px;margin-right:3px;color:#FFF;font-weight:700;padding: 2px 4px;border-radius:2px;font-family: Inconsolata, Menlo, monospace;background:#C00;`,
                elapsedCSS,
            );
        }

        delete (this.pending[id])
    }

    debugColor(color: string) {
        return (arg: string, ...args: any) => {
            this._debug(`% c${arg}`, `background: ${color}; color:#000; font-weight: 700; padding: 1px 6px; border-radius: 2px; `, ...args)
        }
    }
    logRPCEvent(e: { topic: string, event: { type: string } & Record<string, any> }) {

        this.log(
            `%c↓ %c ${e.topic} %c${e.event.type}`,
            arrowCSS,
            idCSS(60) + "padding-right:0;background:magenta;color:#fff",
            idCSS(60, true) + ";margin-left:2px;background:rgba(255, 0, 230, 0.5);color:#000;",
            e.event)
    }
    logRPCSysEvent(event: { type: string } & Record<string, any>) {
        this.log(
            `%c↓ %c${event.type}`,
            arrowCSS,
            methodCSS(24) + "border:1px solid #000;padding-right;color:#00f;background:lime",
            event
        )
    }
    get log() {
        return this._debug;

    }

    get debug() {
        return this._debug;
    }


    get warn() {
        return this._warn;
    }

    get error() {
        return this._error;
    }
}
