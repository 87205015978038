import { AxiosResponse } from "axios";
import v2, { v1 } from "./v2";

export type VideoTokenResponse = {
  token: string,
  app_id: string
}

export type SettingsResponse = {
  emojis: Array<string>
  features: {
    messagesText: boolean
    messagesPhoto: boolean
    livevideo: boolean
    releases: boolean
  },
  imageUrl: string
}

const getVideoToken = ({ accessToken, channel }: { accessToken: string; channel: string }): Promise<AxiosResponse<VideoTokenResponse>> =>
  v2.get(`/video/token?channel=${channel}&token=${accessToken}`);

const getUserByName = (username: string) =>
  v2.get(`/user/by_name/${encodeURIComponent(username)}`);

const getEmojis = (): Promise<Array<string>> =>
  v1.get<{}, AxiosResponse<SettingsResponse>>("/settings").then(({ data }) => data.emojis);

export default { getVideoToken, getUserByName, getEmojis };
