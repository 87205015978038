import Logger from "./Logger";
const logger = new Logger("local-storage-svs");

const localStorageService = {
  setOAuth(oauth: { accessToken: string, refreshToken: string, sfuToken: string }) {
    window.localStorage.setItem("oauth", JSON.stringify(oauth));
  },
  deleteOAuth() {
    window.localStorage.removeItem("oauth");
  },
  getOAuth() {
    try {
      return JSON.parse(window.localStorage.getItem("oauth") as string);
    } catch (e: any) {
      logger.debug(e, "err localStorage oauth");
      return {};
    }
  },
  getAccessToken() {
    const oauth = localStorageService.getOAuth();
    return oauth && oauth.accessToken;
  },
  getRefreshToken() {
    const oauth = localStorageService.getOAuth();
    return oauth && oauth.refreshToken;
  },
  getDeviceId() {
    const d =  window.localStorage.getItem(`deviceID`)

    return d 
      ? d.substr(d.indexOf(":") + 1)
      : null
    },
  setUserDeviceId(deviceId: string) {
    window.localStorage.setItem(`deviceID`, deviceId)
  },
};

export default localStorageService;
