import { OrderResponse } from "@/types";
import { AxiosResponse } from "axios";
import v2 from "./v2";

export function loadOrder(
  token: string
): Promise<AxiosResponse<OrderResponse>> {
  return v2.get(`/api/v2/orders/${token}.json`);
}

export default { loadOrder };
