import localStorageService from "@/application/lib/localStorageService";
import { isJSONRPCResponses, JSONRPCClient, JSONRPCRequest, JSONRPCResponse, JSONRPCServer, JSONRPCServerAndClient } from "json-rpc-2.0";
import ReconnectingWebSocket from 'reconnecting-websocket';
import { TypedEmitter } from "tiny-typed-emitter";
import Logger from "../lib/Logger";
import { BannType, CreateRoomResult, GetBannsResult, GetPeersResult, JoinResult, KickUserResult, KillRoomResult, LeaveResult, Peer, PublishResult, Room, SpeakingEvent, VideoReaction } from "./models";
import { stageName } from "./stageName";


declare interface WsEvents {
  "room.VideoReaction": (e: { reaction: VideoReaction, peer: Peer }) => void
  "connected": (f: boolean) => void
  "disconnected": (f: boolean) => void
  // "ws:send": (msg: any) => void
  // "ws:onmessage": (msg: any) => void
  // "ws:onerror": (msg: any) => void
}

export class WsBus extends TypedEmitter<WsEvents> {
}


export const _bus = new WsBus()


let _idCounter = 1
function batchReqID() {
  return `b${_idCounter++}`
}

const jsonrpc = "2.0"
class Batcher {
  _reqs: JSONRPCRequest[] = []

  request(method: string, params: any) {
    const id = batchReqID()
    this._reqs.push({ method, params, jsonrpc, id })
  }
}



export interface Event {
  type: string
  peer?: Peer
  payload: { [k: string]: any }
}

export interface PubSubEvent {
  id: string
  topic: string
  event: Event
}

export interface SysEvent {
  type: string
  peer: Peer
}



export const logger = new Logger("RPC")

export function cancelable<T>(executor?: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void): PromiseLike<T> & { resolve: (res: T | PromiseLike<T>) => void, reject: (err: any) => void } {
  let resolve = (res: T | PromiseLike<T>) => { }
  let reject = (err: any) => { }

  const p = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve
    reject = _resolve
    executor && executor(resolve, reject)
  })

  return Object.assign(p, { resolve, reject })
}

export default cancelable


let webSocket: ReconnectingWebSocket
let serverAndClient: JSONRPCServerAndClient
let isConnected = cancelable<boolean>()


// export async function rpcNotify<T>(m: string, p: any): T {
// }

export async function rpcCall<T>(m: string, p: any): Promise<T> {
  // await isConnected;

  try {
    const res = await serverAndClient.timeout(5000).request(m, p)
    return res
  } catch (error: any) {
    throw error
  }
}

export async function rpcBatchCall(f: (b: Batcher) => void): Promise<JSONRPCResponse[]> {
  try {
    const b = new Batcher()
    f(b)
    if (b._reqs.length == 0) {
      return Promise.resolve([])
    }
    const res = await serverAndClient.requestAdvanced(b._reqs)
    return res

  } catch (error) {
    throw error
  }
}


export function connect(token: string, username: string, profilePicURL: string, wsToStore: (m: any) => void) {
  const wsHost = location.protocol.replace(/^http/, "ws") + "//" + location.host
  const deviceID = localStorageService.getDeviceId()

  webSocket = new ReconnectingWebSocket(`${wsHost}/bbs20/ws?token=${token}&username=${username}&deviceId=${deviceID}&profilePicURL=${profilePicURL}`);
  webSocket.onerror = (event) => {
    wsToStore({ event: "ws.error" })
    console.log("Error", event);
  }

  serverAndClient = new JSONRPCServerAndClient(
    new JSONRPCServer(),
    new JSONRPCClient((request) => {

      try {
        if (Array.isArray(request)) {
          logger.logRPCBatchReq(request)
        } else {
          logger.logRPCReq(request)
        }

        webSocket.send(JSON.stringify(request));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    })
  );


  webSocket.onmessage = (event) => {
    const response: JSONRPCResponse | JSONRPCResponse[] | PubSubEvent | SysEvent
      = JSON.parse(event.data.toString())

    const res = response as JSONRPCResponse
    const pres = response as PubSubEvent

    if (res.jsonrpc == "2.0" || (Array.isArray(response) && response[0].jsonrpc == "2.0")) {

      if (isJSONRPCResponses(response)) {
        //@ts-ignore
        response.forEach((r) => logger.logRPCRes(r))
      } else {
        //@ts-ignore
        logger.logRPCRes(response)
      }
      serverAndClient.receiveAndSend(response);
    } else if (pres.event != undefined) {
      logger.logRPCEvent(pres)
      onEvent(pres.event)
      wsToStore(pres.event)
    } else if ((response as SysEvent).type != undefined) {
      //@ts-ignore
      logger.logRPCSysEvent(response)
      wsToStore(response)
    }
  };

  // On close, make sure to reject all the pending requests to prevent hanging.
  webSocket.onclose = (event) => {
    isConnected = cancelable<boolean>()
    serverAndClient.rejectAllPendingRequests(
      `Connection is closed(${event.reason}).`
    );
    wsToStore({ event: "ws.closed" })
  };
  webSocket.onerror = (event) => {
    isConnected = cancelable<boolean>()
    serverAndClient.rejectAllPendingRequests(
      `Connection error(${event.type}).`
    );
    wsToStore({ event: "ws.closed" })
  };


  webSocket.onopen = () => {
    isConnected.resolve(true)

    _bus.emit("connected", true)
  }
  return isConnected
}

function onEvent(e: Event) {

  if (!e || !e.payload || !("type" in e.payload)) {
    return
  }
  switch (e.payload.type) {
    case "room.VideoReaction":
      _bus.emit("room.VideoReaction", { reaction: e.payload as VideoReaction, peer: e.peer! })
      break
  }

}

export function connected() {
  return isConnected
}

export function close() {
  webSocket?.close()
  _bus.emit("disconnected", true)
}

export function send(m: any) {
  const j = typeof m == "string" ? m : JSON.stringify(m)
  webSocket?.send(j)
}

// RPC Calls
export function getRooms(): Promise<Room[]> {
  return rpcCall("bbs.GetRooms", [])
}

export function createRoom(roomId: string): Promise<CreateRoomResult> {
  return rpcCall("bbs.CreateRoom", { roomId, record: false })
}

export function videoPublished(roomId: string): Promise<Room> {
  return rpcCall("bbs.VideoPublished", { roomId })
}

export function audioPublished(roomId: string): Promise<Peer> {
  return rpcCall("bbs.AudioPublished", { roomId })
}

export function startSpeaking(roomId: string): Promise<SpeakingEvent> {
  return rpcCall("bbs.StartSpeaking", { roomId })
}

export function stopSpeaking(roomId: string): Promise<SpeakingEvent> {
  return rpcCall("bbs.StopSpeaking", { roomId })
}


export function updatePeer(roomId: string, uid: string, props: { [k: string]: any }): Promise<Peer> {
  return rpcCall("bbs.UpdatePeer", { roomId, uid, props })
}

export function join(roomId: string): Promise<JoinResult> {
  return rpcCall("bbs.Join", { roomId })
}

export function leave(roomId: string): Promise<LeaveResult> {
  return rpcCall("bbs.Leave", { roomId })
}

export function getPeers(roomId: string): Promise<GetPeersResult> {
  return rpcCall("bbs.GetPeers", { roomId })
}

export function subscribe(feedIds: number[]): Promise<string[]> {
  const stage = stageName()
  const topicsN = feedIds.map((fid) => [`${stage}:${fid}:video`, `${stage}:${fid}:audio`, `${stage}:${fid}:feed`])
  //@ts-ignore
  const topics = [].concat.apply([], topicsN);
  return rpcCall("bbs.Subscribe", { topics })
}


export function subscribeTo(topics: string[]): Promise<string[]> {
  return rpcCall("bbs.Subscribe", { topics })
}

export function unsubscribe(feedIds: number[]): Promise<string[]> {
  const stage = stageName()
  const topicsN = feedIds.map((fid) => [`${stage}:${fid}:video`, `${stage}:${fid}:audio`, `${stage}:${fid}:feed`])
  //@ts-ignore
  const topics = [].concat.apply([], topicsN);
  return rpcCall("bbs.UnSubscribe", { topics })
}


export function publish(topic: string, msg: Record<string, any>): Promise<PublishResult> {
  return rpcCall("bbs.Publish", { topic, msg })
}

export function kickUser(userId: number, roomId: string, uid: string, duration = 30): Promise<KickUserResult> {
  return rpcCall("bbs.KickUser", { userId, roomId, uid, duration })
}

export function bannUser(userId: number, roomId: string, typ: BannType, duration = 60 * 60 * 24): Promise<KickUserResult> {
  return rpcCall("bbs.BannUser", { roomId, userId, typ, duration })
}

export function unBannUser(userId: number, roomId: string,): Promise<KickUserResult> {
  return rpcCall("bbs.UnBannUser", { roomId, userId })
}
export function killRoom(roomId: string, duration: number = 30): Promise<KillRoomResult> {
  return rpcCall("bbs.KillRoom", { roomId, duration: Number(duration) })
}


export function getBanns(cursor: number, perPage: number = 100): Promise<GetBannsResult> {
  return rpcCall<GetBannsResult>("bbs.GetBanns", { cursor: Number(cursor), perPage: Number(perPage) })
}
