// app/javascript/setupCSRFToken.js
import { MetaHTMLAttributes } from "vue";
import axios from "axios";

export default function() {
  const csrfToken = (document.querySelector(
    "meta[name=csrf-token]"
  ) as MetaHTMLAttributes)?.content;
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  try {
    const localStorageSupported =
      typeof window["localStorage"] != "undefined" &&
      window["localStorage"] != null;
    if (localStorageSupported) {
      const authLS = window.localStorage.getitem("auth");
      const { jwt } = JSON.parse(authLS);
      axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
    }
  } catch (e) {}
}
