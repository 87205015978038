import { ID } from "@/application/feeds/api"
import { CurrentUser } from "."
import { Product } from "./Product"

enum ReleaseStatus {
  draft = "draft",
  live = "live",
  archived = "archived",
  locked = "locked"
}

export class Release {
  id!: ID
  startsAt!: Date
  endsAt?: Date
  status: ReleaseStatus = ReleaseStatus.draft
  endsWhenSoldOut = true

  user!: CurrentUser
  product!: Product


  static fromJSON(obj: Release): Release {
    const rel = Object.assign(new Release(), obj)
    return rel
  }
}

