import { ID } from "@/application/feeds/api";
import type { User } from "./User";
export type { User } from "./User";
export type role = "Publisher" | "Subscriber";

export enum MessageType {
  roomPing = "roomPing",
  roomAnnounce = "roomAnnounce",
  roomAnnounced = "roomAnnounced",
  roomCreate = "roomCreate",
  roomCreated = "roomCreated",
  roomDestroyed = "roomDestroyed",
  roomJoin = "roomJoin",
  roomJoined = "roomJoined",
  roomLeave = "roomLeave",
  roomLeft = "roomLeft",
  roomError = "roomError",
  roomPublish = "roomPublish",
  roomPublished = "roomPublished",
  roomUnpublish = "roomUnpublish",
  roomUnpublished = "roomUnpublished",
  roomSpeaking = "roomSpeaking",
  roomStopSpeaking = "roomStopSpeaking",
  roomRecStateChanged = "roomRecStateChanged",
  // feeds meta
  feedsSubscribe = "feeds.subscribe",
  feedsSubscribed = "feeds.subscribed",
  feedsPeerCountChanged = "feeds.peerCountChanged",
  feedsRoomCreated = "feeds.roomCreated",
  feedsRoomDestroyed = "feeds.roomDestroyed",
  feedsGetRooms = "feeds.getRooms",
  feedsRooms = "feeds.rooms",
  userStatusChanged = "user.statusChanged"
}


export type VideoToken = {
  token: string;
  appId: string;
  uid: number;
  channel: string;
  role: role;
};

export type LiveStream = {
  id: string;
  count: string;
  created: string;
  username: string
}

export type Room = {
  id: string;
  recId: string
  count: number;
  created: string;
  meta: string;
  started: boolean;
  type: string;
  peers: Record<number, Peer>;
  recording: boolean
  recordingStartedAt?: Date
};

export type Peer = {
  uid: number
  userId: number;
  name: string;
  deviceId: string
  profilePicURL: string;
  isAuthenticated: boolean;
  started?: Date
  speaking: boolean
  published: boolean
  canAudioChat: boolean
};

export type RoomPeer = {
  uid: number
  name: string
  userId: number
  deviceId: string
  profilePicUrl?: string
  speaking?: boolean
  published?: boolean
}

export type userResponse = {
  user: User
}

export type s3PresignedUpload = {
  url: string
  fields: {
    key: string
    policy: string
    "x-amz-credential": string
    "x-amz-algorithm": string
    "x-amz-date": string
    "x-amz-signature": string
  }
}


export namespace msg {
  export type FeedRoom<M = RoomReqMessage> = {
    id: string
    type: string
    feedId: ID
    roomId: string
    version: number
    msg: M
  }

  export type RoomReqMessage =
    | roomCreate
    | roomJoin
    | roomLeave
    | roomAnnounce
    | roomPublish
    | roomUnpublish
    | roomSpeaking
    | roomStopSpeaking

  export type RoomResMessage =
    | roomCreated
    | roomJoined
    | roomLeft
    | roomAnnounced
    | roomPublished
    | roomUnpublished

  export type RoomMessage = RoomReqMessage | RoomResMessage

  export type WsMessage =
    | roomError
    | roomCreate
    | roomCreated
    | roomJoin
    | roomJoined
    | roomLeave
    | roomLeft
    | roomAnnounce
    | roomAnnounced
    | roomPublish
    | roomSpeaking
    | roomStopSpeaking
    | roomRecStateChanged
    | roomPublished
    | roomUnpublished

  type baseMsg = {
    uid: number;
    id: string;
    feedId?: number
    type: string;
    roomId: string;
    roomType: "audio" | "video" | "feed"
    version: number;
  }

  export type roomError = baseMsg & {
    reason: string;

  };

  export type roomJoin = baseMsg & {
    accessToken: string;
    peer: RoomPeer;
  };

  export type roomJoined = baseMsg & {
    created: string,
    recId: string
    peerCount: number;
    peer: RoomPeer;
    token: VideoToken;
    recording: boolean
  };

  export type roomCreate = baseMsg & {
    accessToken: string
    peer: RoomPeer;
  };

  export type roomCreated = baseMsg & {
    created: string,
    peer: RoomPeer;
    peerCount: number
  };

  export type roomDestroyed = baseMsg & {
    peer: RoomPeer;
  };

  export type roomLeave = baseMsg & {
    peer: RoomPeer;

  };

  export type roomLeft = baseMsg & {
    peerCount: number;
    peer: RoomPeer;
  };

  export type roomAnnounce = baseMsg & {
    peer: RoomPeer;
    body: string;
  };

  export type roomAnnounced = baseMsg & {
    peer: RoomPeer;
    body: string;
    room: Room;
  };

  export type roomPublish = baseMsg;

  export type roomPublished = baseMsg & {
    started: Date
  };

  export type roomUnpublish = baseMsg


  export type roomUnpublished = & baseMsg

  export type roomSpeaking = baseMsg & {
    at: number
  };

  export type roomStopSpeaking = baseMsg & {
    at: number
  };

  export type roomRecStateChanged = baseMsg & {
    recId: string
    recording: boolean
    startedAt: Date
  }
}

export type Pagination = {
  page: number,
  pages: number
}

export type VoiceChatPageItem = {
  id: number
  createdAt: Date | string
  awsKey: string
  userIds: Array<number>
  startedAt?: Date
  endedAt?: Date
}

export type VoiceChatPage = { pagination: Pagination, voiceChats: Array<VoiceChatPageItem> }

export type SpeakingEvent = {
  id: number
  userId: number
  eventType: "start" | "stop"
  at: number
}

export type VoiceChat = {
  id: number
  awsKey: string
  createdAt: Date
  startedAt?: Date
  endedAt?: Date
  speakingEvents: Array<SpeakingEvent>
  users: { [userId: string]: User }
}


export type Reaction = {
  id: string
  username: string
  body: string
}

export type PresencePeer = {
  uid: number
  userId: number
  deviceId: string
  profilePicURL: string
  name: string
  joined: string | Date
}

export type PresencePeersMsg = {
  id: string
  type: string
  peers: Array<PresencePeer>
  peerCount: number
  version: number
}


export type PresenceJoinedMsg = {
  id: number
  type: string
  peer: PresencePeer
  peerCount: number
  version: number
}

export type PresenceLeftMsg = {
  id: number
  type: string
  peer: PresencePeer
  peerCount: number
  version: number
}
