import debug from "debug";

const APP_NAME = "bbs";

export default class Logger {

  _debug: debug.Debugger
  _warn: debug.Debugger
  _error: debug.Debugger

  constructor(prefix: string) {
    if (prefix) {
      this._debug = debug(`${APP_NAME}:${prefix}`);
      this._warn = debug(`${APP_NAME}:WARN:${prefix}`);
      this._error = debug(`${APP_NAME}:ERROR:${prefix}`);
    } else {
      this._debug = debug(APP_NAME);
      this._warn = debug(`${APP_NAME}:WARN`);
      this._error = debug(`${APP_NAME}:ERROR`);
    }

    /* eslint-disable no-console */
    this._debug.log = console.log.bind(console);
    this._warn.log = console.warn.bind(console);
    this._error.log = console.error.bind(console);
    /* eslint-enable no-console */
  }

  debug(arg: string, ...args: any) {
    // '%c Oh my heavens! ', 'background: #222; color: #bada55');
    this._debug(
      `%c${arg}`,
      "background:#f1c40f;color:#000;font-weight:700;padding: 1px 6px;border-radius:2px;",
      ...args
    );
  }

  debugEv(arg: string, ...args: any) {
    // '%c Oh my heavens! ', 'background: #222; color: #bada55');
    this._debug(
      `%c${arg}`,
      "background:lightblue;color:#000;font-weight:700;padding: 1px 6px;border-radius:2px;",
      ...args
    );
  }

  debugColor(color: string) {
    return (arg: string, ...args: any) => { this._debug(`%c${arg}`, `background:${color};color:#000;font-weight:700;padding: 1px 6px;border-radius:2px;`, ...args) }
  }

  get warn() {
    return this._warn;
  }

  get error() {
    return this._error;
  }
}
