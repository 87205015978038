import { Message, MessageType, PaginatedList, ValidationErr } from "@/types";
import axios, { AxiosResponse } from "axios";
import { ID } from "../feeds/api";
import v2, { v1 } from "./v2";

export type ListOptions = {
  page: number
  tag?: string
}

export type MessageListResponse = {
  messages: Message[]
  pagination: { page: number, pages: number }
}



type VideoReactionsAPIResponse = {
  videoReactions: {
    [k: number]: [{
      emoji: string
      sentAt: number
      params: Record<string, any>
      userId: number
    }],
  }

  users: {
    [k: number]: {
      name: string
      profilePicUrl: string | null
    }
  }
}



export type VideoReactions = {
  [k: string]: VideoReaction[]
}

export type VideoReaction = {
  emoji: string
  sentAt: number
  params: Record<string, any>
  userId: number
  user: {
    name: string
    profilePicUrl: string | null
  }
}


async function list(o: ListOptions = { page: 1 }): Promise<{ res?: PaginatedList<Message>, err?: any }> {
  try {
    const { data } = o.tag
      ? await v2.get<any, AxiosResponse<MessageListResponse>>(`/tags/${o.tag}?page=${o.page}`)
      : await v2.get<any, AxiosResponse<MessageListResponse>>(`/messages?page=${o.page}`)

    return { res: new PaginatedList(data.messages.map((m) => Message.fromJSONWithUser(m)), data.pagination) }
  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}



async function loadVideoRections(messageIds: Array<number | ID>): Promise<{ res?: VideoReactions, err?: any }> {
  try {
    const { data } = await v2.get<any, AxiosResponse<VideoReactionsAPIResponse>>(`/video_reactions`, { params: { message_ids: messageIds } })

    const entries = Object.entries(data.videoReactions)

    const videoReactions = Object.fromEntries(entries.map(e => {
      const [mid, vrs] = e
      const nvrs = vrs.map(vr => ({ ...vr, user: data.users[vr.userId] }))
      return [mid, nvrs]
    }))

    return { res: videoReactions }
  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}


async function load(id: number | string): Promise<Message> {
  const { data } = await v2.get(`/messages/${id}`)
  return Message.fromJSONWithUser(data)
}

async function destroy(id: number) {
  await v2.delete(`/messages/${id}`);
  return id
}

export type MessageCreateResponse = { message?: Message, err?: ValidationErr }


async function create(msg: Message): Promise<MessageCreateResponse> {
  try {
    if (msg.type == "text") {
      const { data } = await v1.post("/messages", { message: { body: msg.body, mentions: msg.mentions, type: "text" } })
      return { message: data.message }
    }

    const type = msg.type == MessageType.livestreamFrame ? "livestreamFrame" : "photo"
    const { data } = await v1.post("/messages",
      {
        message: {
          body: msg.body,
          mentions: msg.mentions,
          type,
          recId: msg.recId,
          images: msg.images,
          videoStartedAt: msg.videoStartedAt,
          videoEndedAt: msg.videoEndedAt,
          tag: msg.tag
        }
      })
    return { message: data.message }

  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}

async function update(msg: Message): Promise<MessageCreateResponse> {
  try {
    if (msg.type == "text") {
      const { data } = await v1.patch(`/messages/${msg.id}`, { message: { body: msg.body, mentions: msg.mentions, type: "text" } })
      return { message: data.message }
    }
    const { data } = await v1.patch(`/messages/${msg.id}`, { message: { body: msg.body, mentions: msg.mentions, type: "photo", images: msg.images } })
    return { message: data.message }
  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}

function save(msg: Message): Promise<MessageCreateResponse> {
  const isNew = Message.isNew(msg)

  if (isNew) {
    msg.type = Message.messageType(msg)
    return create(msg)
  } else {
    return update(msg)
  }
}

export async function report(id: ID): Promise<boolean> {
  await axios.post("/content_flags", { type: "Message", id })
  return true
}


export default { destroy, list, load, create, update, save, report, loadVideoRections };
