import { Settings } from "@/types/Settings";
import messages from "./messages";
import orders from "./orders";
import releases from "./releases";
import user from "./user";
import { v1 } from "./v2";
import video from "./video";
import voiceChats from "./voiceChats";


const settings = {
  load: async (): Promise<Settings> => {
    const { data } = await v1.get("/settings")
    return data

  }
}

export default { messages, user, voiceChats, orders, video, settings, releases };
