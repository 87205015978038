import v2 from "./v2";
import type { VoiceChat, VoiceChatPage, VoiceChatPageItem } from "@/types/v2";

//: Parameters<(arg0: VoiceChat | VoiceChatPageItem) => VoiceChat | VoiceChatPageItem>[0]
function castDates(vc: VoiceChat | VoiceChatPageItem) {
  vc.createdAt = new Date((vc.createdAt as any) as string)
  vc.startedAt = vc.startedAt! ? new Date((vc.startedAt as any) as string) : undefined
  vc.endedAt = vc.endedAt! ? new Date((vc.endedAt as any) as string) : undefined
  return vc
}

async function getPage(page: number = 1) {
  const { data } = await v2.get<VoiceChatPage>(`/voice_chats?page=${page}`);
  data.voiceChats = data.voiceChats.map(castDates as (v: VoiceChatPageItem) => VoiceChatPageItem)
  return data
}


async function getByRecId(recId: string) {
  const { data } = await v2.get<VoiceChat>(`/voice_chats/show_by_rec_id/${recId}`);
  return castDates(data) as VoiceChat
}

async function getChat(id: number) {
  const { data } = await v2.get<VoiceChat>(`/voice_chats/${id}`);
  return castDates(data) as VoiceChat
}


export default { getPage, getChat, getByRecId };
