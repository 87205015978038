import localStorageService from "@/application/lib/localStorageService";
import { once } from "@/application/lib/once";
import { imgURLLow } from "@/application/utils";
import { CurrentUser } from "@/types";
import { computed, reactive, readonly } from "vue";
import Logger from "../lib/Logger";
import { Feed, Peer, Room, Token, UID, VideoReaction } from "./models";
import { stageName } from "./stageName";
import * as _ws from "./_ws";
export * from "./models";


const logger = new Logger("bbs20-store")

interface stateType {
  token: string | undefined,
  user: CurrentUser | undefined
  me: Peer | undefined,
  rooms: { [roomId: string]: Room }
  tokens: { [roomId: string]: { publishToken?: Token, subscribeToken?: Token } }

  feeds: { [feedId: number]: Feed }
  peers: { [roomId: string]: { [uid: UID]: Peer } }
  isConnected: boolean
  stage: string
}

function initialState(): stateType {
  let user: CurrentUser | undefined
  const token = localStorage.getItem("token") || undefined
  let feeds: { [feedId: number]: Feed } = {}

  return {
    token,
    me: undefined,
    user,
    feeds,
    rooms: {},
    peers: {},
    tokens: {},
    isConnected: false,
    stage: stageName(),
  }
}

let state = reactive(initialState())


function addFeed(feed: Feed) {
  state.feeds = { ...state.feeds, [feed.id]: feed }
}

function setUser(user: CurrentUser) {
  state.user = user
}


function setToken(token: string) {
  state.token = token
}

function unsetToken() {
  state.token = undefined
  state.user = undefined
}

function setMe(me?: Peer) {
  state.me = me
}

function addRoom(room: Room) {
  state.rooms = { ...state.rooms, [room.id]: room }
}

function destroyRoom(roomId: string) {

  delete (state.rooms[roomId])
  state.rooms = { ...state.rooms }
}

function updateRoom(room: { id: string } & Partial<Room>) {

  const exRoom = state.rooms[room.id]
  if (exRoom) {
    room = { ...exRoom, ...room }
  }
  state.rooms = { ...state.rooms, [room.id]: room as Room }

}

function addPeer(roomId: string, peer: Peer) {
  state.peers[roomId] ??= {}
  state.peers[roomId][peer.uid] = peer

  state.peers = { ...state.peers, [roomId]: { ...state.peers[roomId] } }
}



function addToken(roomId: string, token: Token) {
  state.tokens[roomId] ??= {}
  if (token.type == "publish") {
    state.tokens[roomId] = { ...state.tokens[roomId], publishToken: token }
    return
  }
  state.tokens[roomId] = { ...state.tokens[roomId], subscribeToken: token }
}

function delPeer(roomId: string, uid: UID) {
  if (!state.peers[roomId]) {
    return
  }
  delete (state.peers[roomId][uid])

  state.peers = { ...state.peers, [roomId]: { ...state.peers[roomId] } }
}

function updatePeer(roomId: string, peer: Peer | ({ uid: string } & Partial<Peer>)) {
  if (!state.peers[roomId] || !state.peers[roomId][peer.uid]) {
    return
  }
  const oldPeer = state.peers[roomId][peer.uid]
  const oldState = oldPeer.state || {}
  const newState = peer.state || {}
  state.peers[roomId][peer.uid] = Object.assign({}, oldPeer, peer, { state: { ...oldState, ...newState } })
  state.peers = { ...state.peers, [roomId]: { ...state.peers[roomId] } }
}

function login(user: CurrentUser, token: string) {
  setUser(user)
  setToken(token)
  onLogin(user)
}

function logout() {
  // Object
  state.isConnected = false
  state.user = undefined
  state.me = undefined
  state.rooms = {}
  state.feeds = {}
  state.peers = {}

  localStorage.removeItem("user")
  unsetToken()
  try { _ws.close() } catch { }
}

function roomsByFeedId() {
  let rooms: { [feedId: number]: { video?: Room, audio?: Room, feed?: Room } } = {}
  Object.values(state.rooms).forEach(room => {
    rooms[room.feedId] ??= {}
    rooms[room.feedId][room.type] = room
  });

  return rooms
}

export enum EventType {
  wsError = "ws.error",
  wsClosed = "ws.closed",
  connected = "connected",
  closed = "closed",
  roomCreated = "room.Created",
  roomDestroyed = "room.Destroyed",
  roomChanged = "room.StatusChanged",
  peerJoined = "peer.Joined",
  peerLeft = "peer.Left",
  peerUpdated = "peer.Updated",
  videoPublished = "room.VideoPublished",
}

export function wsToStore(msg: { type: string, roomId: string, uid: UID, peer?: Peer, room?: Room }) {
  switch (msg.type) {
    case EventType.wsError:
      logout()
      break
    case EventType.wsClosed:
      break
    case EventType.connected: {
      state.isConnected = true
      setMe(msg.peer!)
      break
    }
    case EventType.closed: {
      setMe(undefined)
      state.isConnected = false
      break
    }
    case EventType.roomCreated:
      addRoom(msg.room!)
      ws.getPeers(msg.room!.id)

      if (msg.room!.type == "audio") {
        ws.join(msg.room!.id)

      }
      break
    case EventType.roomDestroyed:
      destroyRoom(msg.roomId)
      break
    case EventType.videoPublished:
    case EventType.roomChanged:
      updateRoom(msg.room!)
      break
    case EventType.peerJoined:
      addPeer(msg.roomId, msg.peer!)
      break
    case EventType.peerLeft:
      delPeer(msg.roomId, msg.uid)
      break
    case EventType.peerUpdated:
      updatePeer(msg.roomId, msg.peer!)
      break
  }
}

async function connectWs() {
  if (!state.token || !state.user) {
    throw new Error("not logged in !")
  }

  if (!state.isConnected) {
    const deviceId = localStorageService.getDeviceId()

    const token = localStorageService.getRefreshToken() || "";
    const { user } = state
    const profilePicURL = user ? imgURLLow(user.image) : ""
    const username = user?.name || ""
    // state.user = {...state.user, }
    // setUser()
    _ws.connect(state.token, user.name, profilePicURL, wsToStore);
    await _ws.connected();
    state.isConnected = true
    onLogin(state.user!)
  }

  return Promise.resolve(true)
}

async function onLogin(user: CurrentUser) {
  user.feedIDS ??= [0]
  user.feedIDS.forEach(fid => {
    addFeed(new Feed(fid))

  })

  ws.subscribe(user.feedIDS)

  // onConnected(user.feedIDS)

}

function onConnected(feedIDS: number[]) {
  logger.debug("onConnected", { feedIDS })
  state.isConnected = true
  ws.subscribe(feedIDS)
  ws.getRooms()

}

function onDisconnect() {
  logger.debug("onDisconnect")
  state.isConnected = false
}


const ws = {
  bus: _ws._bus,
  connected() {
    return _ws.connected();
  },
  async getRooms() {
    const rooms = await _ws.getRooms()

    if (rooms) {
      rooms.forEach(addRoom);
      const peersRes = await _ws.rpcBatchCall((b) => {
        rooms.forEach(r => {
          b.request("bbs.GetPeers", { roomId: r.id })
        })
      })
      peersRes.forEach(res => {
        if (res.result) {
          res.result.peers.forEach((p: Peer) => addPeer(res.result.roomId, p))
        }
      })

      const rms = rooms.filter((r) => r.type == "audio" || r.type == "video");
      if (rms.length) {

        ws.join(rms[0].id)
      }
    }
  },

  async createRoom(roomId: string) {
    const ex = state.rooms[roomId]
    if (!ex) {
      const room = Room.buildWithRoomId(roomId, state.me!)

      addRoom(room)
    }

    const roomRes = await _ws.createRoom(roomId);

    if (roomRes.room) {
      updateRoom({ ...roomRes.room })

      roomRes.isNew && addPeer(roomId, state.me!)
      !roomRes.isNew && ws.getPeers(roomId)

      roomRes.publishToken && addToken(roomRes.room.id, roomRes.publishToken)
      roomRes.subscribeToken && addToken(roomRes.room.id, roomRes.subscribeToken)
    }

    return roomRes
  },

  async videoPublished(roomId: string) {
    const room = await _ws.videoPublished(roomId);
    if (room) {
      updateRoom({ ...room, streamState: "published" })
    }
    return room
  },

  async audioPublished(roomId: string) {
    const peer = await _ws.audioPublished(roomId);
    if (peer) {
      updatePeer(roomId, peer)
      updateRoom({ id: roomId, streamState: "published" })
    }
    return peer
  },

  async updatePeer(roomId: string, uid: string, props: { [k: string]: any }): Promise<Peer> {
    updatePeer(roomId, { uid, ...props })
    const peer = await _ws.updatePeer(roomId, uid, props)
    updatePeer(roomId, peer)
    return peer
  },

  async startSpeaking(roomId: string) {
    const uid = state.me!.uid
    const s = state.peers[roomId] && state.peers[roomId][uid]
    updatePeer(roomId, { uid: uid, state: { ...s, speaking: true } })

    const ev = await _ws.startSpeaking(roomId);

    return ev
  },

  async stopSpeaking(roomId: string) {
    const uid = state.me!.uid
    const s = state.peers[roomId] && state.peers[roomId][uid]

    updatePeer(roomId, { uid: uid, state: { ...s, speaking: false } })
    const ev = await _ws.stopSpeaking(roomId);

    return ev
  },

  async join(roomId: string) {
    if (roomId.includes("undefined")) {
      debugger
    }
    const res = await _ws.join(roomId);
    addPeer(res.roomId, state.me!)

    res.publishToken && addToken(res.roomId, res.publishToken)
    res.subscribeToken && addToken(res.roomId, res.subscribeToken)
    return res
  },
  async leave(roomId: string) {
    const res = await _ws.leave(roomId);
    delPeer(res.roomId, state.me!.uid)
    return res
  },
  async getPeers(roomId: string) {
    const res = await _ws.getPeers(roomId);
    res.peers.forEach((p) => addPeer(res.roomId, p))
  },
  publish(topic: string, msg: Record<string, any>) {
    return _ws.publish(topic, msg)
  },
  subscribe(feedIds: number[]) {
    return _ws.subscribe(feedIds);
  },
  unsubscribe(feedIds: number[]) {
    return _ws.unsubscribe(feedIds);
  },



  async videoRoomReaction(roomId: string, emoji: string, params: Record<string, any>): Promise<VideoReaction> {

    // const user =
    // const params = {
    //   id: ulid(),
    //   dimension: 68.404853694114252,
    //   userId: user.id,
    //   username: user.name,
    //   duration: 3.189394321280826,
    //   yShift: 162.96426498345468,
    //   createdAt: new Date(),
    //   body: emoji
    // }

    // const body = JSON.stringify({ reaction })
    // const req = {
    //   body,
    //   emoji,

    //   type: v1.MessageType.liveStreamAnnounce,
    //   msgId: ulid(),
    // }

    const reaction = {
      emoji,
      params,
    }

    _ws.rpcCall("bbs.VideoReaction", { roomId, reaction })



    return Promise.resolve(reaction)
  },
  close() {
    _ws.close();
  }
}

const subscribeVBus = once(() => {
  _ws._bus.on("connected", (f: boolean) => onConnected(state.user?.feedIDS || [0]))
  _ws._bus.on("disconnected", (f: boolean) => onDisconnect())
})

export const useBbs20Store = () => {
  subscribeVBus()
  return {
    state: readonly(state),
    //getters
    me: computed<Peer | undefined>(() => state.me),
    rooms: computed<Room[]>(() => Object.values(state.rooms)),
    roomsByFeedId: computed(() => roomsByFeedId()),
    feeds: computed<Feed[]>(() => Object.values(state.feeds)),
    token: computed<string | undefined>(() => state.token),
    user: computed<CurrentUser | undefined>(() => state.user),
    isConnected: computed<boolean>(() => state.isConnected),
    getPeers: (roomId: string) => computed(() => Object.values(state.peers[roomId] || [])),
    getRoom: (roomId: string) => computed(() => state.rooms[roomId]),
    getFeed: (feedId: number) => computed(() => state.feeds[feedId]),
    getPubToken: (roomId: string) => computed(() => state.tokens[roomId] && state.tokens[roomId].publishToken),
    getSubToken: (roomId: string) => computed(() => state.tokens[roomId] && state.tokens[roomId].subscribeToken),
    // mutations
    addFeed,
    addRoom,
    updateRoom,
    destroyRoom,
    addPeer,
    updatePeer,
    login,
    logout,
    setUser,
    setToken,
    //actions
    connectWs,
    ws
  }
}
