import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import moment from "moment-timezone";
import { ulid } from "ulid";

dayjs.extend(relativeTime);

export function timeSince(dateStr: string): string {
  return dayjs().to(dayjs(dateStr));
}

export function getTouchMouseTargetElement(e: TouchEvent) {
  if (e.touches) {
    return document.elementFromPoint(e.touches[0].pageX, e.touches[0].pageY);
  }
  return e.target;
}

export const isEmpty = (v: null | undefined | string | number | Object) =>
  v === null ||
  v === undefined ||
  (typeof v == "string" && v.trim() === "") ||
  Object.keys(v).length == 0;

export const notEmpty = (v: null | undefined | string) => !isEmpty(v);

export const wssProto = () =>
  location.protocol === "https" || location.protocol === "https:"
    ? "wss"
    : "ws";

export const isEmoji = (str: string) => {
  var ranges = [
    "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])" // U+1F680 to U+1F6FF
  ];
  if (str.match(ranges.join("|"))) {
    return true;
  } else {
    return false;
  }
};

export const now = () => {
  let n = new Date();
  return n.toLocaleString(navigator.language, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true
  });
};

const REFERENCE = moment();
const TODAY = REFERENCE.clone().startOf("day");
const YESTERDAY = REFERENCE.clone()
  .subtract(1, "days")
  .startOf("day");
const A_WEEK_OLD = REFERENCE.clone()
  .subtract(7, "days")
  .startOf("day");

function isToday(momentDate: moment.Moment) {
  return momentDate.isSame(TODAY, "d");
}
function isYesterday(momentDate: moment.Moment) {
  return momentDate.isSame(YESTERDAY, "d");
}
function isWithinAWeek(momentDate: moment.Moment) {
  return momentDate.isAfter(A_WEEK_OLD);
}
function isTwoWeeksOrMore(momentDate: moment.Moment) {
  return !isWithinAWeek(momentDate);
}

export const messageFullDate = (dateStr: string) => {
  let m = moment(dateStr);
  return m.format("MMMM Do, YYYY");
};

export const compDate = (dateStr: string) => {
  let m = moment(dateStr);
  return m.format("YYYY-MM-DD");
};

// Used for more precised date/time strings, mostly for a :key.
export const compDateSeconds = (dateStr: string) => {
  let m = moment(dateStr);
  return m.format("YYYY-MM-DD h:mm:ss");
};

export const messageDate = (dateStr: string) => {
  let m = moment(dateStr);

  // return m.format("h:mm A");

  if (isToday(m)) {
    return `${m.format("h:mm A")} today`;
  } else if (isYesterday(m)) {
    return `${m.format("h:mm A")} yesterday`;
  }

  return m.format("h:mm A on M/DD/YYYY");
};

export const messageNow = () => moment().format("h:mm A on M/DD/YYYY");

export const uuidv4 = ulid;
// ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
//   (
//     c ^
//     (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
//   ).toString(16)
// );

const UUID_RE = /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$/;

export const isNewMessage = (msg: { id: any }) => isNaN(msg.id - 0);

export function isMobile() {
  if (/iPhone/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
}
export const imgVersion = () => {
  const w = screen.width;
  if (w < 375) {
    // 375 @ 1x HEIGHT
    return "feed_x";
  } else if (w < 414) {
    // 11 PRO
    // iphone 11 pro max is 414 but let include some other phones as well (eg  pixel max witch is 411 i think)
    // 375 x HEIGHT
    // 375 x 3 HEIGHT
    return "feed_x3";
  } else if (w < 768) {
    // PRO MAX
    // 375 x HEIGHT
    return "feed_xm3";
  }
  // 768 x HEIGHT
  return "feed";
};

export const imgS3Url = (img: { url: string }) => {
  if (!img.url) return "";
  const idx = img.url.indexOf("https://splay-uploads");
  return img.url.substring(idx);
};

export const imgURL = (data: { url: string }, version = imgVersion()) => {
  var url = data.url;
  const dpr = "devicePixelRatio" in window ? window.devicePixelRatio : 2;
  return `${url}`.replace("${width}", `${screen.width},${dpr}x`);
};

export const imgURLLow = (data: { url?: string } | string, w = 100) => {
  if (!data) {
    return "";
  }
  const url = typeof data === "string" ? data : data.url || "";
  const dpr = "devicePixelRatio" in window ? window.devicePixelRatio : 2;
  return `${url}`.replace("${width}", `${w},${dpr}x`);
};

export const imgURLThumb = (data: { url: string }, w = 100) => {
  if (!data) {
    return TRANSPARENT_PNG_URL;
  }
  var url = data.url;
  const dpr = "devicePixelRatio" in window ? window.devicePixelRatio : 2;
  return `${url}`.replace("${width}", `${w}x${w},${dpr}x,crop=auto`);
};

export const imageWidthRatio = () => {
  const windowEl = document.querySelector(
    "#app .layout-default"
  ) as HTMLElement;
  const w = windowEl ? windowEl.offsetWidth : 600;
  const r = window.devicePixelRatio;
  return { w: w, r };
};

export const TRANSPARENT_PNG_URL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkUAMAACwAKPAXDTMAAAAASUVORK5CYII=";

export const imgURLImageoptim = (data: { url: string }) => {
  if (!data) {
    return TRANSPARENT_PNG_URL;
  }
  var url = data.url;
  const { w, r } = imageWidthRatio();

  return `${url}`.replace("${width}", `${w},${r}x`);
};

export const deviceOrientation = () => {
  switch (window.orientation) {
    case -90:
    case 90:
      return "landscape";
      break;
    default:
      return "portrait";
      break;
  }
};

export function stringToHslColor(str: string, s: number = 100, l: number = 70) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
}

// export const lineItemsForApplePay = (items, pending = true) => {
//   for(var i = 0; i <
//   items.push({type: "pending", label: names.join(', '), amount: product.amount});
// }

// export function buildInitialPaymentRequest(lineItems) {
//   var paymentRequest = {
//     // TODO: country code is what? How to determine currency?
//     countryCode: "US",
//     currencyCode: "USD",
//     total: {
//       type: "pending", // TODO - only final billing address is set, maybe?
//       label: "Splay",
//       amount: 1, // Won't be shown, since its pending.
//     },

//     // TODO: Now we get differnet shipping and billing info?
//     lineItems: lineItems,
//     requiredShippingContactFields: ["postalAddress", "name", "phone", "email"],
//     requiredBillingContactFields: ["postalAddress", "name", "phone", "email"],
//     supportedNetworks: ["masterCard", "visa", "discover", "amex"],
//     merchantCapabilities: ["supports3DS"],
//   };
//   return paymentRequest;
// }

// fromPDP = from product detail page.
// // If its from the PDP we need to clear and build a new cart.
// export const applePayButtonClicked = (lineItems, fromPDP = false) => {
//   /**
//    * Sample payment data
//    */
//   var paymentRequest = buildInitialPaymentRequest(lineItems);
//   var applepayVersion = 2;
//   var session = new ApplePaySession(applepayVersion, paymentRequest);
//   session.fromPDP = fromPDP; // Need to track this for use in the callbacks.

//   /**
//    * Makes an AJAX request to your application server with URL provided by Apple
//    */
//   function getSession(url) {
//     return new Promise(function (resolve, reject) {
//       var xhr = new XMLHttpRequest();
//       var requestUrl = "/applepay/session";

//       xhr.open("POST", requestUrl);

//       xhr.onload = function () {
//         console.log("Get session: ", xhr.response);
//         if (this.status >= 200 && this.status < 300) {
//           return resolve(JSON.parse(xhr.response));
//         } else {
//           return reject({
//             status: this.status,
//             statusText: xhr.statusText,
//           });
//         }
//       };

//       xhr.onerror = function () {
//         return reject({
//           status: this.status,
//           statusText: xhr.statusText,
//         });
//       };

//       xhr.setRequestHeader("Content-Type", "application/json");

//       return xhr.send(
//         JSON.stringify({
//           url: url,
//         })
//       );
//     });
//   }

//   session.onshippingcontactselected = function (event) {
//     console.log(event);
//     // If it's coming from the PDP, clear the session out so we have an empty cart, since we need to add this product to the cart, so it's the only product we're checking out with via applepay.
//     if (session.fromPDP) {
//       clearCart();
//       addToCart(
//         _currentSize.id,
//         _currentStyle.id,
//         _currentProduct.id,
//         function () {
//           updateCheckoutTotalsForApplePay(session, event.shippingContact);
//         }
//       );
//     } else {
//       updateCheckoutTotalsForApplePay(session, event.shippingContact);
//     }
//   };

//   /**
//    * Merchant Validation
//    * We call our merchant session endpoint, passing the URL to use
//    */
//   session.onvalidatemerchant = function (event) {
//     var validationURL = event.validationURL;
//     var promise = getSession(event.validationURL);
//     promise.then(function (response) {
//       session.completeMerchantValidation(response);
//     });
//   };

//   /**
//    * This is called when user dismisses the payment modal
//    */
//   session.oncancel = function (event) {
//     if (session.fromPDP) {
//       console.log("TODO: CANCELLED");
//       // $('#applepay_buy_now').removeClass('loading');
//     }
//   };

//   /**
//    * Payment Authorization
//    * Here you receive the encrypted payment data. You would then send it
//    * on to your payment provider for processing, and return an appropriate
//    * status in session.completePayment()
//    */
//   session.onpaymentauthorized = function (event) {
//     var payment = event.payment;
//     // You can see a sample `payment` object in an image below.
//     // Use the token returned in `payment` object to create the charge on your payment gateway.
//     // TODO - how to test this?
//     // Capture payment from Apple Pay
//     // Send the info to our server, and show confirmation.
//     submitApplePayToCE(
//       payment,
//       function () {
//         if (session.fromPDP) {
//           ga("send", "event", "checkout", "applepay-from-product", "success");
//         } else {
//           ga("send", "event", "checkout", "applepay-from-checkout", "success");
//         }

//         session.completePayment(ApplePaySession.STATUS_SUCCESS);
//       },
//       function (errorField, errorFieldMessage) {
//         if (session.fromPDP) {
//           console.log("APPLEPAY ERROR!", errorField, errorFieldMessage);
//         }
//         // Applepay error specific display.
//         if (errorField) {
//           // We don't actually use this error object, unless it's the invalid Nike country:
//           // var error = new ApplePayError("shippingContactInvalid", errorField, errorFieldMessage);
//           session.completePayment(
//             ApplePaySession.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS
//           );
//         } else {
//           console.log("unspecific error");
//           session.completePayment(ApplePaySession.STATUS_FAILURE);
//         }
//       }
//     );
//   };

//   /**
//    * This will show up the modal for payments through Apple Pay
//    */
//   session.begin();
// };

export const isLocal = () =>
  document.querySelector('[name="rails-env-development"]') !== null;
// location.href == "https://b2020.splay.com/";

export const iOS = () =>
  ["iPad", "iPhone", "iPod"].indexOf(navigator.platform) >= 0;

export const toHHMMSS = (secs: string) => {
  let sec = parseInt(secs, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor(sec / 60) % 60;
  let seconds = sec % 60;

  return [hours, minutes, seconds]
    .map(v => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};

export const dataURLtoBlob = (dataURL: string) => {
  // # Decode the dataURL
  const binary = atob(dataURL.split(",")[1]);
  // # Create 8-bit unsigned array
  const array = [];
  let i = 0;
  while (i < binary.length) {
    array.push(binary.charCodeAt(i));
    i++;
  }
  // # Return our Blob object
  return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
};

// https://www.htmlgoodies.com/html5/javascript/display-images-in-black-and-white-using-the-html5-canvas.html
export function grayScaleImage(
  context: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement
) {
  var imgData = context.getImageData(0, 0, canvas.width, canvas.height);
  var pixels = imgData.data;
  for (var i = 0, n = pixels.length; i < n; i += 4) {
    var grayscale =
      pixels[i] * 0.3 + pixels[i + 1] * 0.59 + pixels[i + 2] * 0.11;
    pixels[i] = grayscale; // red
    pixels[i + 1] = grayscale; // green
    pixels[i + 2] = grayscale; // blue
    //pixels[i+3]              is alpha
  }
  //redraw the image in black & white
  context.putImageData(imgData, 0, 0);
}

export function openIOSApp(splayProtocolURL: string, appStoreURL: string) {
  var now = new Date().valueOf();
  const iframe = document.createElement("IFRAME");

  iframe.setAttribute("src", splayProtocolURL);
  iframe.classList.add("splay-detect");

  document.body.appendChild(iframe);

  const tid = setTimeout(function () {
    if (new Date().valueOf() - now > 123) {
      return;
    }

    window.location.href = appStoreURL;
  }, 25);
}

export const dollars = (cents: number) => {
  return `$ ${Number.parseFloat("" + cents / 100).toFixed(2)}`;
};

export const releaseEndDate = (endsAt: string | Date) => moment(endsAt).format("ll");

export const releaseEndTime = (endsAt: string | Date) => moment(endsAt).format("LT");

export function shuffle<T>(array: Array<T>): Array<T> {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const formatDuration = (
  startDate: Date,
  { end, short } = { end: new Date().getTime(), short: false }
) => {
  const st = startDate.getTime();
  if (short) {
    return new Date(end - st).toISOString().substr(14, 5);
  }
  return new Date(end - st).toISOString().substr(11, 8);
};

export const formatDurationISOStr = (
  startDateISOStr: string,
  { end, short } = { end: new Date().getTime(), short: false }
) => {
  return formatDuration(new Date(Date.parse(startDateISOStr)), {
    end,
    short
  });
};

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const initSwipeEvents = (el, deltaMin = 80) => {
  const swipeData = {
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0
  }
  let directionEvents = []
  el.addEventListener("touchstart", e => {
      const t = e.touches[0]
      swipeData.startX = t.screenX
      swipeData.startY = t.screenY
  })
  el.addEventListener("touchmove", e => {
      const t = e.touches[0]
      swipeData.endX = t.screenX
      swipeData.endY = t.screenY
  })
  el.addEventListener("touchend", () => {
      const deltaX = swipeData.endX - swipeData.startX
      const deltaY = swipeData.endY - swipeData.startY

      if (Math.abs(deltaX) > deltaMin) {
          if (deltaX > 0) directionEvents.push("right")
          else directionEvents.push("left")
      }
      if (Math.abs(deltaY) > deltaMin) {
          if (deltaY > 0) directionEvents.push("down")
          else directionEvents.push("up")
      }

      directionEvents.forEach(direction =>
          el.dispatchEvent(new Event(`swipe-${direction}`))
      )

      directionEvents = []
  })
}
