import { FeedToken, ID } from "@/application/feeds/api";
import { Feed } from "@/types";
import { ulid } from "ulid";
import { RoomPeer } from "./v2";


export namespace feedWS {
  export type RoomType = "audio" | "video" | "feed"

  export enum FeedTypes {
    join = "feed.join",
    joined = "feed.joined",
    leave = "feed.leave",
    left = "feed.left",
    announce = "feed.announce",
    announced = "feed.feedAnnounced",
    idMissingError = "feed.feedIDMissingError",
    err = "feed.feedErr",
    notAMemberErr = "feed.notAMemberErr",
  }

  type Base = {
    feedId: number
    id: string
    roomId: string
    version: 2
  }

  type peerMsg = Base & {
    peer: RoomPeer
    peerCount: number
  }

  type peersMsg = Base & {
    peers: RoomPeer[]
    peerCount: number
  }

  export type join = Base & peerMsg & {
    type: FeedTypes.join
  }

  export type joined =
    peerMsg & {
      type: FeedTypes.joined
    } |
    peersMsg & {
      type: FeedTypes.joined
    }

  export type leave = peerMsg & {
    type: FeedTypes.leave
  }

  export type left = peerMsg & {
    type: FeedTypes.left
  }

  export type announce = peerMsg & {
    type: FeedTypes.announce
  }

  export type announced = peerMsg & {
    type: FeedTypes.announced
  }

  export type err = peerMsg & {
    type: FeedTypes.err
  }

  export type msg = join
    | joined
    | leave
    | left
    | announce
    | announced
}

export namespace feedsWS {
  const version = 2

  export type PeerCountsType = { [feedId: number]: { [roomType: string]: number } }


  export enum FeedsTypes {
    subscribe = "feeds.subscribe",
    subscribed = "feeds.subscribed",
    peerCountChanged = "feeds.peerCountChanged",
    roomCreated = "feeds.roomCreated",
    roomDestroyed = "feeds.roomDestroyed",
    roomJoined = "feeds.roomJoined",
    roomLeft = "feeds.roomLeft",
    getRooms = "feeds.getRooms",
    rooms = "feeds.rooms"
  }



  export type RoomType = "audio" | "video" | "feed"

  interface WsBase {
    id: string
    uid: number
    type: FeedsTypes
    roomId: string
    feedId: number
    version: number
  }

  export interface subscribe extends WsBase {
    type: FeedsTypes.subscribe
    token: string
  }

  export interface subscribed extends WsBase {
    type: FeedsTypes.subscribed
    peerCounts: PeerCountsType
  }

  export interface peerCountChanged extends WsBase {
    type: FeedsTypes.peerCountChanged
    peerCounts: { [feedType: number]: number }
  }

  export interface roomCreated extends WsBase {
    type: FeedsTypes.roomCreated
    roomType: string
    peerCount: number
    peer: RoomPeer
    created: string
  }

  export interface roomDestroyed extends WsBase {
    type: FeedsTypes.roomDestroyed
    roomType: string
    peerCount: number
    peer: RoomPeer
  }

  export interface roomJoined extends WsBase {
    type: FeedsTypes.roomJoined
    roomType: string
    peerCount: number
    peer: RoomPeer
  }



  export interface roomLeft extends WsBase {
    type: FeedsTypes.roomLeft
    roomType: string
    peerCount: number
    peer: RoomPeer
  }

  export interface feedsRooms {
    id: string
    type: FeedsTypes.rooms
    version: 2
    rooms: Array<FeedsRoom>
  }

  export type FeedsRoom = {
    roomId: string
    roomType: string
    feedId: number
    peerCount: number
    created: string
    peers: RoomPeer[]
  }

  export type msg = subscribe | subscribed | peerCountChanged | roomCreated | roomDestroyed | roomJoined | roomLeft



  export function feedsSubscribe(token: FeedToken) {
    return {
      id: ulid(),
      type: "feeds.subscribe",
      token,
      version
    }
  }

  export function feedsGetRooms(token: FeedToken) {
    return {
      id: ulid(),
      type: "feeds.getRooms",
      token,
      version
    }
  }

  export function joinFeed(feed: Feed, token: FeedToken) {
    return {
      id: ulid(),
      type: feedWS.FeedTypes.join,
      roomId: `feed-${feed.id}-messages`,
      feedId: feed.id,
      token,
      version
    }
  }

  export function feedLeave(feed: Feed) {
    return {
      id: ulid(),
      type: feedWS.FeedTypes.leave,
      roomId: `feed-${feed.id}-messages`,
      feedId: feed.id,
      version
    }
  }


  export interface FeedPeerCountChanged {
    feedId: ID
    id: string
    peerCounts: PeerCountsType
    roomID: string
    roomType: "messages" | "audio" | "video"
    type: "feeds.peerCountChanged"
    version: 2
  }

  export function isFeedsRoom(arg: any): arg is FeedsRoom {
    return arg && arg.roomId && typeof (arg.roomId) == 'string'
      && arg.roomType && typeof (arg.roomType) == 'string'
      && arg.feedId && typeof (arg.feedId) == 'number'
      && arg.peerCount && typeof (arg.peerCount) == 'number'
      && arg.peers && typeof (arg.peers) == 'object'
      && arg.created && typeof (arg.created) == 'string'
      && !arg.peer
  }

}


