
export interface Pagination {
  page: number
  pages: number
}

export interface ListItem {
  id: number | string
}

export class PaginatedList<T extends ListItem> {
  items!: T[]
  loading = false
  pagination!: Pagination
  err?: any

  constructor(items?: T[], pagination?: Pagination) {
    this.items = items || []
    this.pagination = pagination || { page: 0, pages: 0 }
  }

  clear() {
    this.items = []
    this.pagination = { page: 0, pages: 0 }
    this.loading = false
    this.err = undefined
  }

  find(id: string | number) {
    return this.items.find(i => i.id == id)
  }

  add(item: T) {
    const idx = this.items.findIndex(e => e.id == item.id)
    if (~idx) {
      return false
    }
    this.items.unshift(item)
    return true
  }

  update(id: string | number, item: Partial<T>) {
    //@ts-ignore
    const idx = this.items.findIndex(e => id == e.id || e.id == item.tmpId)

    if (~idx) {
      this.items.splice(idx, 1, { ...this.items[idx], ...item })
      return true
    }
    return false
  }

  delete(id: string | number) {
    const idx = this.items.findIndex(e => e.id == id)
    if (~idx) {
      this.items.splice(idx, 1)
      return true
    }
    return false
  }

  append(page: PaginatedList<T>) {
    this.pagination = page.pagination
    if (this.pagination.page == 1) {
      this.items = page.items
      return
    }
    this.items.concat(page.items)
  }
}
