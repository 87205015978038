import { RoomType, StageType } from "../store"


export function stage() {
    switch (location.host) {
        case "www.splay.com":
            return "prod"

        case "staging.splay.com":
            return "staging"
        default:
            return "dev"
    }
}


export function genRoomId(feedId: number, typ: RoomType) {
    return `${stage()}:${feedId}:${typ}`
}

export function parseRoomId(roomId: string): { stage: StageType, feedId: number, type: RoomType } {
    const [stage, feedId, type] = roomId.split(":")

    return { stage: stage as StageType, feedId: Number(feedId), type: type as RoomType }

}

