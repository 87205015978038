export function stageName(): string {
  switch (location.hostname) {
    case "www.splay.com":
    case "splay.com":
      return "prod";
    case "staging.splay.com":
      return "staging";
    default:
      return "dev";
  }
}
