import { Message, PaginatedList, Profile } from "@/types";
import { CurrentUser } from "@/types/User";
import type { userResponse } from "@/types/v2";
import { s3PresignedUpload } from "@/types/v2";
import { ValidationErr } from "@/types/ValidationErr";
import axios, { AxiosResponse } from "axios";
import imageCompression from 'browser-image-compression';
import _omit from "lodash/omit";
import localStorageService from "../lib/localStorageService";
import { ListOptions } from "./messages";
import { v0, v1, v2 } from "./v2";


export async function makeDummyUser(): Promise<CurrentUser> {
  const { data } = await v2.post("/users/make_dummy_pending_user.json");
  return data
}

export async function verifySmsCode(code: String, phone: String): Promise<CurrentUser> {
  const { data } = await v2.post("/users/verify_sms_code.json", { phone: phone, code: code });
  return data
}

export async function submitPhoneForVerification(phone: String): Promise<CurrentUser> {
  const { data } = await v2.post("/users/send_sms_verification_code.json", { phone: phone });
  return data
}

async function _loadCurrentUser(t = ""): Promise<CurrentUser> {
  const { data } = await v1.get(`/users/me.json?_t=${t}`);
  return data.user
}

let __loadCurrentUser: Promise<CurrentUser>
export async function loadCurrentUser(reload?: boolean): Promise<CurrentUser> {
  if (reload) {
    __loadCurrentUser = _loadCurrentUser(new Date().getTime().toString())
  } else {
    __loadCurrentUser ??= _loadCurrentUser()
  }
  return __loadCurrentUser
}

export type Oauth = {
  accessToken: string;
  refreshToken: string;
  sfuToken: string;
}

export async function loadRefreshToken(): Promise<{ res?: Oauth, err?: any }> {
  try {
    const refreshToken = localStorageService.getRefreshToken()
    const { data } = await v0.post("/oauth/token", { refresh_token: refreshToken })

    localStorageService.setOAuth(data)
    return { res: data }
  } catch {
    return { err: new Error("can't load token") }
  }
}


export async function loadProfile(p: { name: string } & ListOptions): Promise<{ res?: { user: CurrentUser, messages: PaginatedList<Message> }, err?: any }> {
  try {
    const { data } = await v2.get<any, AxiosResponse<Profile>>(`/profile/by_name/${p.name}?page=${p.page}`);
    return { res: { user: data.user!, messages: new PaginatedList(data.messages, data.pagination) } }
  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}

export type CurrentUserResponse = { currentUser?: CurrentUser; err?: ValidationErr }

export async function updateCurrentUser(user: CurrentUser): Promise<CurrentUserResponse> {
  const { name, bio, igHandle, image } = user
  try {
    const { data } = await v1.patch("/users/me.json", { user: { name: `${name}`.trim(), bio, igHandle: `${igHandle}`.trim(), image: _omit(image, ["url", "position"]) } });
    return { currentUser: data.user }
  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}

export function presignedUpload(ext: string): Promise<AxiosResponse<s3PresignedUpload>> {
  return v2.post(`/s3_uploads/presigned_post`, { ext })
}

export async function uploadToS3(upl: s3PresignedUpload, file: File, cb: (p: number) => void) {
  const options = {
    onUploadProgress: (e: { loaded: number, total: number }) => {
      const perccent = Math.round((e.loaded * 100) / e.total)
      cb(perccent)
    },
  }

  let formData = new FormData()
  Object.keys(upl.fields).forEach(key => {
    // @ts-ignore-line
    formData.append(key, upl.fields[key])
  })
  formData.append("file", file);


  return axios.create().post(upl.url, formData, options)
}

export async function updateImage(file: File, progressFunc: (p: number) => void): Promise<AxiosResponse<userResponse>> {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }
  const cf = await imageCompression(file, options)

  const ext = file.name.replace(/^.*\./, '').toLowerCase();

  const { data } = await presignedUpload(ext)

  await uploadToS3(data, cf, progressFunc)

  return v1.patch("/users/me", { user: { image: { imageKey: data.fields.key, imageWidth: 500, imageHeight: 500 } } })
}

export async function search(q: string) {
  const { data } = await v2.get(`/users/search?q=${q}`)
  return data
}

export default { loadCurrentUser, loadRefreshToken, loadProfile, updateCurrentUser, updateImage, presignedUpload, uploadToS3, search };

