import { ID } from '@/application/feeds/api';
import { Feed } from '@/types/Feed';
import type { Mention } from "@/types/Mention";
import Autolinker from "autolinker";
import imageCompression from 'browser-image-compression';
import { ulid } from 'ulid';
import * as yup from 'yup';
import type { EditableImage, Image } from "./Image";
import { UserWithPic } from "./UserWithPic";


const autolinker = new Autolinker();

export enum MessageType {
  text = "text",
  photo = "photo",
  livestreamFrame = "livestreamFrame"
}

export class Message {
  id!: ID
  tmpId?: string
  feedId!: number
  userId!: number
  user!: UserWithPic
  type!: MessageType
  tag: string | null = null
  body: string = ""
  images = Array<Image>()
  editableImages = Array<EditableImage>()
  mentions = Array<Mention>()
  commentsCount = 0
  imageFile?: File
  createdAt!: Date
  progress = 0
  // version!: number
  typing = false
  recId?: string
  videoUrl?: string
  publicUrl?: string
  editing = false
  videoStartedAt?: Date
  videoEndedAt?: Date


  static initialize(json: Message | Partial<Message>, user: UserWithPic): Message {
    const m: Message = Object.assign(new Message(), json, { user: UserWithPic.initialize(user) });
    return m
  }

  static build(feedId = 0): Message {
    const m = new Message()
    m.id = ulid()
    m.feedId = feedId
    return m
  }


  static fromJSONWithUser(json: Message): Message {
    const m: Message = Object.assign(new Message(), json, { user: UserWithPic.initialize(json.user) });
    return m
  }


  private static newMessage(feed: Feed, user: UserWithPic, type: MessageType): Message {
    let m: Message = Object.assign(new Message(), {
      id: ulid(),
      user,
      userId: user.id,
      feedId: feed.id,
      feed,
      type,
      createdAt: new Date()
    })
    return m
  }

  static newTextMessage(feed: Feed, user: UserWithPic): Message {
    return Message.newMessage(feed, user, MessageType.text)
  }

  static newPhotoMessage(feed: Feed, user: UserWithPic, img?: File): Message {
    return Message.newMessage(feed, user, img ? MessageType.photo : MessageType.text)
  }

  static schemaText() {
    return yup.object().shape({
      // id: yup.number(),
      type: yup.string().required(),
      body: yup.string().required().max(65535),
      mentions: yup.array<Mention>(),
      commentsCount: yup.number(),
      createdAt: yup.date()
    })
  }

  static isNew(m: { id: ID }) {
    return (typeof m.id == "string") && m.id.length == 26
  }


  static parseHTML(html: string): { body: string, mentions: Array<Mention> } {
    return { body: html, mentions: [] }
  }

  static toHTML(message: Message): string {
    if (message.mentions.length == 0) {
      return autolinker.link(message.body);
    }

    let html = "";
    let idx = 0;

    message.mentions.forEach((m, k) => {
      html += autolinker.link(message.body.substring(idx, m.start));

      const name = message.body.substr(m.start, m.length).trim();
      html += `<a class="mention" data-user-name="${encodeURIComponent(name.substr(1))}">${name}</a> `;

      idx = m.start + m.length;

      if (k == message.mentions.length - 1) {
        html += autolinker.link(message.body.substr(idx));
      }
    });

    return html;
  }

  static messageType(m: Message): MessageType {
    if (m.type == MessageType.livestreamFrame) {
      return MessageType.livestreamFrame
    }
    return m.images.length > 0 || m.imageFile ? MessageType.photo : MessageType.text
  }

  static async imageFromSrc(src: string, idx = 0, id = ulid()): Promise<EditableImage> {

    let fileType = ""
    let { length, [length - 1]: ext } = src.split(".")
    ext = ext.toLowerCase()

    if (["jpg", "jpeg"].includes(ext)) {
      fileType = "image/jpeg"
    } else if (["png"].includes(ext)) {
      fileType = "image/png"
    }

    const progress = src.startsWith("data:image") ? 0 : 1

    const fileName = `${ulid()}.${ext}`
    const fileLastModified = new Date().getTime() / 1000

    const img = await imageCompression.loadImage(src);
    const { width, height } = img
    // const canv = await imageCompression.drawImageInCanvas(img, fileType)
    // const file = await imageCompression.canvasToFile(canv, fileType, fileName, fileLastModified, 100)

    return { width, height, src, idx, id, progress }
  }

  static async imageFromFile(file: File, idx = 0, id = ulid()): Promise<EditableImage> {
    const src = await imageCompression.getDataUrlFromFile(file)
    const { width, height } = await imageCompression.loadImage(src);
    return { width, height, src, file, idx, id, progress: 0 }
  }
}
