import axios from "axios";
import localStorageService from "../lib/localStorageService";

export const v2 = axios.create({
  baseURL: "/api/v2",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

// Add a request interceptor
v2.interceptors.request.use(
  config => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export default v2;

export const v1 = axios.create({
  baseURL: "/api/v1",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

// Add a request interceptor
v1.interceptors.request.use(
  config => {
    const token = localStorageService.getAccessToken();

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export const v0 = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

// Add a request interceptor
v0.interceptors.request.use(
  config => {
    const token = localStorageService.getAccessToken();

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
