import { imgURLLow } from "../application/utils"

export class UserWithPic {
  id!: number
  name!: string
  profilePicUrl?: string = undefined

  static initialize(j: UserWithPic): UserWithPic {
    const profilePicUrl = j.profilePicUrl ? imgURLLow(j.profilePicUrl) : undefined
    return Object.assign(new UserWithPic(), j, { profilePicUrl })
  }
}
