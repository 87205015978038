import { PaginatedList } from "@/types";
import { Release } from "@/types/Release";
import axios, { AxiosResponse } from "axios";
import { v1 } from "./v2";

async function list(
  page: number = 1
): Promise<{ res?: PaginatedList<Release>; err?: any }> {
  try {
    const { data } = await v1.get<any, AxiosResponse<{ releases: [Release] }>>(
      `/releases?page=${page}`
    );
    if (!data.releases)
      return {
        res: new PaginatedList<Release>()
      };
    else
      return {
        res: new PaginatedList<Release>(
          data.releases.map(rel => Release.fromJSON(rel)),
          { page, pages: 1 }
        )
      };
  } catch (error) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data };
    }
    throw error;
  }
}

export default { list };
