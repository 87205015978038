import { genRoomId, parseRoomId } from "../lib/utils";


export interface CreateRoomResult {
  isNew: boolean;
  room: Room;
  publishToken: Token;
  subscribeToken: Token;
}

export interface JoinResult {
  roomId: string;
  publishToken: Token;
  subscribeToken: Token;
  wasJoined: boolean;
}
export interface LeaveResult {
  roomId: string;
  wasJoined: boolean;
}

export interface GetPeersResult {
  roomId: string;
  peers: Peer[];
}

export interface SpeakingEvent {
  at: number;
  uid: string;
  type: string;
}

export interface VideoReaction {
  id?: string
  type?: "room.videoReaction"
  emoji: string,
  sentAt?: number
  params: Record<string, any>
};

export interface PublishResult {
  topic: string;
  ok: boolean;
}

export interface KickUserResult {
  roomID: string
  ok: boolean;
}

export interface KillRoomResult {
  roomID: string
  ok: boolean;
}

export type BannType = "ForEver" | "Room" | "Duration"

export interface Bann {
  userId: number
  roomId: string
  typ: BannType //"forever" | "room" | "duration"
  duration: number
  untilTS: number
  user?: User
}
export interface GetBannsResult {
  banns: Array<Bann>
  cursor: number
  perPage: number

}

export type RoomType = "audio" | "video" | "feed"
export type StageType = "dev" | "staging" | "prod"
export interface Token {
  id: number
  value: string
  type: "publish" | "subscribe"
  streamName: string
}


export class User {
  UserID!: Number;
  Name!: string;
  ProfilePicURL!: string;
  DeviceID!: string;
  FeedIDS!: number[]
}

export type StreamState = "off" | "creating" | "connecting" | "on" | "published" | "disabled";

export class Room {
  id!: string
  recId!: string
  stage!: StageType
  type!: RoomType
  feedId!: number
  created!: string | Date
  creator!: Peer
  state: { [k: string]: any } = {}
  streamName!: string
  streamState: StreamState = "off"
  peerCount = 0


  //
  static buildWithRoomId(roomId: string, creator: Peer): Room {
    const { stage, feedId, type } = parseRoomId(roomId)

    let room = new Room()

    room.feedId = feedId
    room.type = type
    room.creator = creator
    room.id = roomId
    room.stage = stage

    room.type != "feed" && (room.streamState = "connecting")
    return room
  }

  static isCreator(room: Room, peer: Peer): boolean {
    return room.creator.uid == peer.uid
  }
}

export type UID = string

export class Peer {
  deviceId!: string
  name!: string
  profilePicUrl!: string
  uid!: UID
  userId!: number
  joined!: string | Date
  token?: Token
  speaking!: boolean
  multiplexedId?: number
  audioLevel?: number
  muted?: boolean
  state?: { [k: string]: any }

  get profilePicURL() {
    return this.profilePicUrl
  }
}

export class Feed {
  id!: number
  videoRoomId!: string
  audioRoomId!: string
  feedRoomId!: string

  constructor(id: string | number) {
    this.id = Number(id)
    this.videoRoomId = genRoomId(this.id, "video")
    this.audioRoomId = genRoomId(this.id, "audio")
    this.feedRoomId = genRoomId(this.id, "feed")
  }


  get name() {
    return `Feed ${this.id}`
  }


}
